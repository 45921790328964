import React from 'react'
import pic from "../assets/bg/contact_pic.png"
const ContactDetails = () => {
  return (
    <div className='flex flex-col md:flex-row-reverse gap-5 md:gap-14 px-3 md:p-10 md:pb-0 xl:p-20 justify-center xl:gap-20 pt-10  xl:px-24'>
         <img className='md:w-1/2 xl:w-1/3 m-auto' src={pic} alt='mountains'/>
        <div className='w-full md:w-1/2 flex flex-col gap-10 md:pt-5'>
               <h1 className='text-3xl md:text-4xl xl:text-6xl text-center font-playfair text-[#2D3A1B]'>Get In Touch</h1>
       <iframe className="text-3xl md:text-4xl w-full h-80 text-white rounded-2xl font-semibold font-serif"
        title="crm-form"
        id="Contact_Form"
        src="https://account.solidperformers.com/capture_form_data/OTM3"
        border="0"

      ></iframe>
        </div>
       
     
    </div>
  )
}

export default ContactDetails
