import React from "react";
// import icon_facebook from '../assets/icons/icon_facebook.png';
// import icon_insta from '../assets/icons/icon_insta.png';
// import icon_linkdin from '../assets/icons/icon_linkdin.png';
// import icon_twitter from '../assets/icons/icon_twitter.png';
import brand_logo from './assets/icons/the-shubham-group1final.png';



const Footer = () => {

  const phoneNumber = "8510850101";

  return (
    <footer id="contact" className="bg-[#f1f7f2] py-5">
      <div className="container mx-auto flex flex-col md:flex-row items-centem-5 p-3 gap-10 md:gap-5 lg:gap-20 ">

      <section className="flex flex-col w-full md:w-2/5 lg:w-1/5">
           {/* <div className=" flex gap-10 md:gap-4 mx-auto">
            <a href="https://www.facebook.com/theshubhamGroup/" target="_blank">
                <img className="w-10 bg-white rounded-lg animate__zoomIn2 hover:shadow-lg hover:shadow-slate-300" src= {icon_facebook} alt="Facebook Link.."/></a>

            <a href="https://twitter.com/theshubhamgroup" target="_blank">
                <img className="w-10 bg-white rounded-lg animate__zoomIn2 hover:shadow-lg hover:shadow-slate-300" src= {icon_twitter} alt="Twitter Link.."/></a>

            <a href="https://www.instagram.com/theshubhamgroup/" target="_blank">
                <img className="w-10 bg-white rounded-lg animate__zoomIn2 hover:shadow-lg hover:shadow-slate-300" src= {icon_insta} alt="Insta Link.."/></a>
                
            <a href="https://www.linkedin.com/company/shubham-group-of-companies/" target="_blank">
                <img className="w-10 bg-white rounded-lg animate__zoomIn2 hover:shadow-lg hover:shadow-slate-300" src= {icon_linkdin} alt="Linkdin Link.."/></a>
          </div> */}
          <a href="https://www.tsgoc.in" target="_blank" rel="noreferrer">
                <img className="w-44 md:h-56 md:pt-10 lg:pt-0 lg:w-56 mx-auto rounded-lg hover:shadow-lg hover:shadow-slate-300 animate__zoomIn" src= {brand_logo} alt="Linkdin Link.."/></a>
        </section>
        <section className="flex flex-col md:w-fit w-full lg:pt-5 gap-5 md:gap-10 text-center md:text-justify">
          <p className="text-black text-2xl md:text-3xl font-semibold">Registered Office</p>
          <p className="text-zinc-700 text-lg font-times font-thin hover:underline">
            The Shubham Group of Companies, <br/>A-24, Okhla Industrial Area, Phase-2,<br/> Delhi,
            Pincode - 110020
          </p>
        </section>

        <section className="flex flex-col w-full lg:pt-5 md:w-fit gap-5 md:gap-10  text-center md:text-justify">
          <p className="text-black text-2xl md:text-3xl font-semibold">Corporate Office</p>
          <p className="text-zinc-700 text-lg font-times font-thin hover:underline ">
            315, Block-B,<br/> Sector-105 Noida,<br/> Uttar Pradesh, Pincode - 201304
          </p>
        </section>

        <section className="flex flex-col w-full lg:pt-5 md:w-fit  gap-5 md:gap-10 text-center md:text-justify">
          <p className="text-black text-2xl md:text-3xl font-semibold">Contact Us</p>
          <div className="flex flex-col ">
            <a className="text-zinc-700 text-lg hover:underline hover:text-blue-400 " href="mailto:info@theshubhamgroup.com"> info@theshubhamgroup.com</a>
          </div>
          
        </section>
      
      </div>
      <section class="copy-right">
        <p className="text-zinc-700  text-center text-sm font-times p-3 pt-0">Copyright © 2009 The Shubham Group of Companies All Rights Reserved</p>
    </section>
    </footer>
  );
};

export default Footer;
