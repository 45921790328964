import React from "react";
import bg from "../assets/bg/About_bg.jpg";
import bg2 from "../assets/bg/home_bg.jpg";

import bird from "../assets/bg/bird-2.png"
import wifi from "../assets/amenities-icons/wifi.png";
import kids from "../assets/amenities-icons/kids.png";
import garden from "../assets/amenities-icons/garden.png";
import gym from "../assets/amenities-icons/gym.png";
import parking from "../assets/amenities-icons/parking.png";
import himalayan from "../assets/amenities-icons/hill view.png"

const AboutUs2 = () => {
  const bgStyle = {
    backgroundImage: `url(${bg})`,
    backgroundAttachment: "fixed",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center middle",
  };

  const sectionClass =
    "flex flex-col md:flex-row gap-5 md:w-5/6 justify-center mx-auto  p-2 md:py-5 rounded-2xl";

  return (
    <div
      className="flex flex-col gap-10 "
      style={{ ...bgStyle, backgroundImage: `url(${bg2})` }}
    >

<div className="bg-[#9CA898] bg-opacity-60 xl:bg-opacity-0  md:p-10 p-3 ">

   <div
        className="rounded-2xl"
        //  style={bgStyle}
      >
        <div className={sectionClass}>
          <div className="flex flex-col font-playfair text-xl md:text-3xl xl:text-5xl justify-center text-[#FFFFF0] xl:text-[#2D3A1B] items-center text-center gap-2 md:gap-5">
            <h1>About Chail Heights Villas</h1><img className="h-24 xl:h-40 xl:w-[400px] xl:relative right-10 xl:ml-16" src={bird} alt="bird"/>
          </div>
          <div className="font-quicksand text-white xl:text-black text-justify text-base md:text-lg xl:text-xl flex flex-col gap-2 md:gap-3 w-full md:w-2/3">
            <p>
              We are excited to announce our new residential project called{" "}
              <span className="font-semibold text-[#EBE1B3] xl:text-neutral-500">
                Chail Heights Villas
              </span>
              , a residential gated society consisting of{" "}
              <span className="font-semibold text-[#EBE1B3] xl:text-neutral-500">
                13 independent villas
              </span>{" "}
              <span className="font-semibold text-[#EBE1B3] xl:text-neutral-500">
                
              </span>{" "}
              and{" "}
              <span className="font-semibold text-[#EBE1B3] xl:text-neutral-500">
                48 1BHK ultra luxurious apartments
              </span>{" "}
              <span className="font-semibold text-[#EBE1B3] xl:text-neutral-500">

              </span>
              .
            </p>
            <p>
              This project is being developed by{" "}
              <span className="font-semibold text-[#EBE1B3] xl:text-neutral-500">
                The Shubham Group
              </span>
              , a developer who has already delivered{" "}
              <span className="font-semibold text-[#EBE1B3] xl:text-neutral-500">
                five residential projects
              </span>{" "}
              on hills located in{" "}
              <span className="font-semibold text-[#EBE1B3] xl:text-neutral-500">
                Uttarakhand
              </span>{" "}
              and{" "}
              <span className="font-semibold text-[#EBE1B3] xl:text-neutral-500">
                Himachal Pradesh
              </span>
              . The project is located in{" "}
              <span className="font-semibold xl:text-neutral-500">
                Chail, Himachal Pradesh, near Chail Palace
              </span>
              .
            </p>
            {/* <ol className="flex flex-col gap-2 md:gap-5 pt-5">
             
              <li>Fully wifi zone</li>
              <li>Play area for kids</li>
              <li>Common garden</li>
              <li>Gymnasium</li>
              <li>Parking lot</li>
            </ol>  */}
            <p className="text-[#FFFFF0] xl:text-[#2D3A1B] text-center text-lg md:text-2xl xl:text-3xl py-5 font-playfair">
              Amenities and necessities provided in the Project
            </p>
            <ol className="grid grid-cols-2 md:grid-cols-3 gap-3 px-5 md:py-5 w-full justify-center text-[#FFFFF0] xl:text-[#2D3A1B] text-lg md:text-xl xl:text-2xl font-playfair">
            <li className="flex flex-col md:hover:scale-105 duration-500 items-center p-2 rounded-xl text-center gap-3">
                <img
                  className="h-16"
                  src={parking}
                  alt="TRAVEL DESK"
                />{" "}
                Parking lot
              </li>
              <li className="flex flex-col md:hover:scale-105 duration-500 items-center p-2 rounded-xl text-center gap-3">
                <img
                  className="h-16"
                  src={himalayan}
                  alt="WARDROBE"
                />{" "}
               Paranomic Himalayan View
              </li>
              <li className="flex flex-col md:hover:scale-105 duration-500 items-center p-2 rounded-xl text-center gap-3">
                <img
                  className="h-16 "
                  src={kids}
                  alt="WARDROBE"
                />{" "}
                Play area for kids
              </li>
              <li className="flex flex-col md:hover:scale-105 duration-500 items-center p-2 rounded-xl text-center gap-3">
                <img
                  className="h-16"
                  src={garden}
                  alt="parking"
                />{" "}
                Common garden
              </li>
              <li className="flex flex-col md:hover:scale-105 duration-500 items-center p-2 rounded-xl text-center gap-3">
                <img
                  className="h-16 "
                  src={gym}
                  alt="HOUSE KEEPING"
                />{" "}
                Gymnasium
              </li>
           
              <li className="flex flex-col md:hover:scale-105 duration-500 items-center p-2 rounded-xl text-center gap-3">
                <img
                  className="h-16"
                  src={wifi}
                  alt="WARDROBE"
                />{" "}
                Wifi enabled area
              </li>
            </ol>
          </div>
        </div>
      </div>

      <div
        className="rounded-2xl"
        //  style={bgStyle}
      >
        <div className={sectionClass}>
          <div className="w-1/2 mx-auto flex justify-center font-playfair text-2xl md:text-3xl xl:text-5xl text-[#FFFFF0] xl:text-[#2D3A1B] items-center gap-2">
            <h2 className="text-center ">Why Chail?</h2>
          </div>
          <div className="font-quicksand text-white xl:text-black text-justify text-base md:text-lg xl:text-xl flex flex-col gap-2 md:gap-3 w-full md:w-2/3">
            <p>
              Chail is an unexplored location with a very low population, away
              from all the hustle and bustle of Shimla and Solan. Chail also
              witnesses amazing weather year-round.
            </p>
            <p>
              It is 45 kilometers from Solan city and 44 kilometers from Shimla.
              It is known for its salubrious beauty and virgin forests.
            </p>
          </div>
        </div>
      </div>
</div>
     
    </div>
  );
};

export default AboutUs2;
